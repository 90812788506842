<template>
  <v-container>
      <v-dialog v-model="dialog" persistent max-width="1200">
          <v-toolbar dark flat>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('page_name.merchant_activity_schedule') }}</v-toolbar-title>
            </v-toolbar>
          <v-card>
              <v-card-text>
                    <v-container fluid>
                          <v-row>
                                <v-col cols="6">
                                    <v-select
                                        :items="selectServiceList"
                                        v-model="selectedService"
                                        item-value="value"
                                        item-text="text"
                                        prepend-icon="mdi-order-bool-ascending-variant"
                                        :loading="selecterLoading"
                                        :label="$t('service_date_times.select')"
                                        disabled
                                    />
                                </v-col>
                                <v-spacer/>
                                <v-col class="d-flex justify-end">
                                    <v-btn v-if="selectedService" dark @click="create">
                                        {{$t('service_date_times.create')}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedService && serviceAvailableDates != ''">
                                <v-col cols="12" class="py-0">
                                    <h3> {{$t('service_date_times.select_date')}} </h3>
                                </v-col>
                                <v-col cols="12">
                                    <v-date-picker
                                        no-title
                                        dark
                                        v-model="selectedDate"
                                        :allowed-dates="getAllowedDates"
                                        color="primary"
                                        :show-current="false"
                                        event-color="red"
                                        :events="getAllowedDates"
                                        :min="min"
                                        :max="max"
                                        full-width
                                        class="mt-4"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pa-2" v-if="selectedService && selectedDate">
                                    <h2>{{ $t('service_date_times.selected_date') + ' ' + selectedDate}}</h2>
                                    <v-data-table
                                        mobile-breakpoint=0
                                        class="elevation-10"
                                        :loading="loading"
                                        :headers="i18nHeader"
                                        :items="filteredList"
                                        :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
                                    >
                                        <template v-slot:item="{ item }">
                                            <tr>
                                                <td> {{ $moment(item.startAt).format('MM/DD HH:mm') + ' ~ ' + $moment(item.endAt).format('MM/DD HH:mm') }}</td>
                                                <td>
                                                    <v-btn small :color="(item.published)? 'success': 'error'" @click="changeStatus(item)"> <v-icon left>mdi-{{(item.published)? 'check': 'close'}}</v-icon> {{showStatus(item.published)}}</v-btn>
                                                </td>
                                                <td>
                                                    <v-btn @click="viewUser(item)" icon>
                                                        <v-icon color="grey lighten-1">
                                                            mdi-account-circle
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                                <td>
                                                    <v-icon @click="edit(item)">
                                                        mdi-pencil
                                                    </v-icon>
                                                </td>
                                            </tr>
                                            </template>
                                    </v-data-table>
                                </v-col>
                                <v-col cols="12" v-if="selectedService && serviceDateTimesList == ''">
                                    <v-card class="mx-auto">
                                        <v-card-title primary-title>
                                            {{$t('service_date_times.nodata')}}
                                        </v-card-title>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <ServiceDateTimeDialog
                                ref="dialog"
                                @refresh="getAllService"
                                @refreshService="getAllServiceDateTimeByID"
                                :serviceList="selectServiceList"
                            />
                            <EditServiceDateTimeDialog
                                ref="editDialog"
                                @refresh="getAllService"
                                @refreshService="getAllServiceDateTimeByID"
                                :serviceList="selectServiceList"
                                :selectedService="selectedService"
                            />
                            <v-dialog
                                v-model="userDialog"
                                max-width="850px"
                            >
                                <v-card>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-data-table
                                                mobile-breakpoint=0
                                                class="elevation-4"
                                                :headers="i18nOrderHeader"
                                                hide-default-footer
                                                :items="orderList"
                                                disable-pagination
                                            >
                                                <template v-slot:item="{ item }">
                                                    <tr>
                                                        <td>{{ item.merchantId.name }} </td>
                                                        <td>{{ item.userId.name }} </td>
                                                        <td>{{ item.userId.email }} </td>
                                                        <td>{{ (!item.paymentMethod && item.packageOrderId)? $t('page_name.package') : (!item.paymentMethod) ? "--" : item.paymentMethod[ (lang == 'tc' && item.paymentMethod['nameZh'] )? 'nameZh' : 'nameEng']  }} </td>
                                                        <td>{{ showOrderStatus(item.status) }} </td>
                                                    </tr>
                                                    </template>
                                            </v-data-table>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>
                            <confirmDialog ref="confirmDialog"/>
                    </v-container>
              </v-card-text>
          </v-card>
      </v-dialog>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import ServiceDateTimeDialog from "@/views/ServiceDateTimes/ServiceDateTimeDialog"
import EditServiceDateTimeDialog from "@/views/ServiceDateTimes/EditServiceDateTimeDialog"
import confirmDialog from "@/components/ConfirmDialog"
import api from '@/api'

export default {
    components:{
        ServiceDateTimeDialog,
        EditServiceDateTimeDialog,
        confirmDialog
    },
    computed:{
        ...mapState(['lang']),
        i18nHeader(){
            let result = this.headers
            return result.map(item => item.text = this.$t(item.i18n))
        },
        i18nOrderHeader(){
            let result = this.orderHeaders
            return result.map(item => item.text = this.$t(item.i18n))
        },
        i18nOrderStatus(){
            let result = this.orderStatusArr;
            for (let i = 0; i < result.length; i++) {
                var item = result[i];
                item.text = this.$t(item.i18n)
            }
            return result;
        },
        handledAreaList(){
            let result = []
            if(this.areasList && this.areasList != ''){
                let areaArr = this.areasList['area']
                let location = this.areasList['subArea']

                areaArr.map( item => {
                    result.push({
                        'region': item[(this.lang == 'tc')? 'nameZh' : 'nameEng'],
                        'location': location[item.alias]
                    })
                })
            }
            return result
        },
        selectServiceList(){
            let result = []

            if(this.serviceList && this.serviceList != '' && this.serviceList.length>0) this.serviceList.map( item => result.push({value:item._id, text: item.name, price: item.price}) )

            return result
        },
        filteredList(){
            let result = []
            if(this.serviceDateTimesList) result = this.serviceDateTimesList.filter(item => this.$moment(item.startAt).format('YYYY-MM-DD') == this.selectedDate)
            return result
        }
    },
    watch: {
        selectedService: function (newID) {
            if(this.selectedService) this.getAllServiceDateTimeByID(newID)
            this.selectedDate = ''
        }
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        open(id){
            this.selectedService = id
            this.dialog = true
            this.getAllService()
            this.getAllCategories()
            this.getAllTeacher()
            this.getAllAreas()
        },
        async getAllService() {
            this.selecterLoading = true
            try{
                let result = await api.get('/services/all')

                if(result){
                    this.serviceList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
                this.loading = false
            }
            this.selecterLoading = false
        },
        async getAllServiceDateTimeByID(id) {
            console.log(id);
            this.loading = true
            try{
                let result = await api.get('/serviceDateTimes/by-service/'+id)

                if(result){
                    let tmpArray = []
                    this.serviceDateTimesList = result.data.serviceDateTimes

                    result.data.serviceDateTimes.forEach(item => {
                        let formatedDate = this.$moment(item.startAt).format('YYYY-MM-DD')
                        if(tmpArray[tmpArray.length-1] != formatedDate) tmpArray.push(formatedDate)
                    });

                    this.serviceAvailableDates = tmpArray.sort((a,b) => this.$moment(a).valueOf() - this.$moment(b).valueOf())

                    this.min = this.serviceAvailableDates[0];
                    this.max = this.serviceAvailableDates[this.serviceAvailableDates.length-1];
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        async changeStatus(item){
            let action = await this.$refs.confirmDialog.open(
                {
                    title  : this.$t('service_date_times.update_status'),
                    content: this.$t('service_date_times.qa_update_status') + `${(item.published)? this.$t('service_date_times.qa_update_status_ban') : this.$t('service_date_times.qa_update_status_active')}?`,
                })
            if (action) {
                let result = false

                item.published = (item.published)? false : true

                try {
                    result = await api.patch('/serviceDateTimes/'+item._id, item)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                if (result) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_update_status'),
                        icon   : 'mdi-check',
                    })
                }
            }
        },
        async getAllTeacher() {
            this.loading = true
            try{
                let result = await api.get('/teacher')

                if(result){
                    this.teacherList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        async getAllCategories() {
            let apiTarget = (this.permissions == 'admin')? '/category' : '/category/published'

            try{
                let result = await api.get(apiTarget)
                if(result) this.categoryList = result.data
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllAreas() {
            try{
                let result = await api.get('/setting/areas')

                if(result){
                    this.areasList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        handledCategories(category){
            let findedCat = category

            if(category && this.categoryList.length > 0){
                this.categoryList.map(item => {
                    if(item._id == category) findedCat = item[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
                })
            }

            return findedCat
        },
        handledTeacher(teacher){
            let result = teacher

            if(teacher && this.teacherList.length > 0){
                this.teacherList.map(item => {
                    if(item._id == teacher) result = item[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
                })
            }

            return result
        },
        getAllowedDates (val) {
            return (this.serviceAvailableDates.indexOf(val) != -1)? true : false
        },
        showStatus(status){
            return (status)? this.$t('common.enabled'):this.$t('common.disabled');
        },
        showOrderStatus(status){
            var newstatus = this.i18nOrderStatus.find( item => item.value == status);
            return newstatus.text
        },
        async viewUser(item){
            try{
                let result = await api.get('/orders?serviceDateTimeId=' + item._id)
                this.orderList = result.data.docs
                this.userDialog = true
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        create(){
            this.$refs.dialog.open('create', '', this.selectedService)
        },
        edit(item){
            this.$refs.editDialog.open('edit', item)
        },
        close(){
            this.dialog = false
            this.selectedService = ''
            this.serviceAvailableDates = []
            this.serviceDateTimesList = []
            this.serviceList = []
        }
    },
    data: () => ({
        headers: [
            {i18n: 'service.time',value: '', sortable: false},
            {i18n: 'common.status',value: '', sortable: false},
            {i18n: 'common.view_user', value: '', sortable: false },
            {i18n: 'common.edit', value: '', sortable: false },
        ],
        orderHeaders: [
            {i18n: 'common.merchant',value: 'merchantId'},
            {i18n: 'common.user',value: '', sortable: false},
            {i18n: 'common.email_user',value: '', sortable: false},
            {i18n: 'common.payment_method',value: '', sortable: false},
            {i18n: 'common.status',value: 'status'},
        ],
        orderStatusArr: [
            {i18n: 'order.waiting_payment', value: 'waitingForPayment'},
            {i18n: 'order.waiting_confirm', value: 'waitingForConfirm'},
            {i18n: 'order.confirmed', value: 'confirmed'},
            {i18n: 'order.cancelled', value: 'cancelled'},
        ],
        selectedService: '',
        serviceAvailableDates: [],
        serviceList: [],
        serviceDateTimesList: [],
        teacherList: [],
        categoryList: [],
        areasList:[],
        orderList:[],
        selectedDate: '',
        min: '',
        max: '',
        selecterLoading: false,
        userDialog: false,
        loading: false,
        dialog: false,
        show: false
    })
}
</script>
<template>
    <v-container>
        <v-dialog v-model="dialog" persistent fullscreen hide-overlay>
            <v-toolbar dark flat>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('service_date_times.create') }}</v-toolbar-title>
                <v-spacer />
                <v-btn text @click="save(action)">{{ $t('common.save') }}</v-btn>
            </v-toolbar>
            <v-card :loading="loading">
                <v-card-text>
                    <v-container fluid>
                        <v-form ref="form" v-model="formValid" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-select v-model="selectedCreateType" :items="i18nCreateTypeList" item-value="value"
                                        item-text="text" :label="$t('service_date_times.create_type')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedCreateType">
                                <v-col cols="12">
                                    <v-select v-model="serviceDateTimeDetailObj.serviceId" :items="serviceList"
                                        item-value="value" item-text="text" :label="$t('service.name')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>

                            </v-row>
                            <v-row v-if="selectedCreateType == 'range'">
                                <v-col cols="4">
                                    <v-menu v-model="rangeStartDateDialog" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="rangeStartDate"
                                                :label="$t('service_date_times.start_at_day')" prepend-icon="mdi-calendar"
                                                readonly v-bind="attrs" v-on="on" />
                                        </template>
                                        <v-date-picker v-model="rangeStartDate" :min="$moment().format('YYYY-MM-DD')"
                                            :max="rangeEndDate" @input="rangeStartDateDialog = false" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="4">
                                    <v-menu v-model="rangeEndDateDialog" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="rangeEndDate"
                                                :label="$t('service_date_times.end_at_day')" prepend-icon="mdi-calendar"
                                                readonly v-bind="attrs" v-on="on" />
                                        </template>
                                        <v-date-picker v-model="rangeEndDate" :min="rangeStartDate"
                                            @input="rangeEndDateDialog = false" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="4">
                                    <v-select v-model="selectedWeekDay" :items="i18nWeekdayList" item-value="value"
                                        item-text="text" :label="$t('service_date_times.select_weekday')" multiple />
                                </v-col>
                            </v-row>
                            <v-row v-if="dateTimeResult != ''">
                                <v-col align="center">
                                    <v-btn dark @click="updatePreviewDate">{{ $t('service_date_times.preview') }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectedCreateType == 'multiple' || PreviewDate">
                                <v-col cols="12">
                                    <v-date-picker no-title v-model="selectedDate" :show-current="false" full-width
                                        elevation="10" dark multiple />
                                </v-col>
                            </v-row>


                            <v-row v-if="selectedCreateType">
                                <v-col cols="12">
                                    <v-select v-model="selectedPeriodMode" :items="i18nCreatePeriodMode" item-value="value"
                                        item-text="text" :label="$t('service_date_times.create_period')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>

                                <v-col cols="12">
                                    <v-row v-if="selectedPeriodMode == 'allfixed'">
                                        <v-col cols="12">
                                            <v-row>
                                                <v-col cols="3">
                                                    <v-text-field v-model="serviceDateTimeDetailObj.price" :rules="[
                                                        v => !!v || $t('common.required'),
                                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                    ]" :label="$t('common.price')" />

                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field v-model="serviceDateTimeDetailObj.originalPrice"
                                                        :label="$t('common.original_price')" />
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field v-model="serviceDateTimeDetailObj.quantity" :rules="[
                                                        v => !!v || $t('common.required'),
                                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                    ]" :label="$t('common.quantity')" />
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field v-model="serviceDateTimeDetailObj.maxQuantityPerUser"
                                                        :rules="[
                                                            v => !!v || $t('common.required'),
                                                            v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                                            v => parseInt(v) <= parseInt(serviceDateTimeDetailObj.quantity) || $t('common.valid_maxquantity')
                                                        ]" :label="$t('common.max_quantity')" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row
                                                v-if="(rangeStartDate && rangeEndDate && selectedWeekDay) || (selectedCreateType == 'multiple' && selectedDate)">
                                                <v-col cols="12">
                                                    <v-card class="px-3 mb-7" v-for="(item, index) in dateTimeRowArr "
                                                        :key="index">
                                                        <v-card-title>
                                                            {{ $t('service.time') + ((selectedCreateType == 'multiple') ? ''
                                                                : index + 1) }}
                                                            <v-spacer />
                                                            <v-btn dark @click="removeMultiRow(index)">
                                                                <v-icon>mdi-trash-can-outline</v-icon>
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-row>
                                                                <v-col cols="6">
                                                                    <h2>{{ $t('service.start_at') }}:</h2>
                                                                    <v-time-picker :landscape="$vuetify.breakpoint.smAndUp"
                                                                        v-model="item.startAt" :max="item.endAt"
                                                                        format="24hr"
                                                                        :rules="[v => !!v || $t('common.required')]" />
                                                                </v-col>

                                                                <v-col cols="6">
                                                                    <v-row align="center" justify="space-between">
                                                                        <h2>{{ $t('service.end_at') }}:</h2>
                                                                        <v-switch v-model="item.isEndNextDay"
                                                                            :label="$t('service_date_times.end_next_day')" />
                                                                        <h2></h2>
                                                                    </v-row>
                                                                    <div v-if="item.isEndNextDay === true">
                                                                        <v-time-picker
                                                                            :landscape="$vuetify.breakpoint.smAndUp"
                                                                            v-model="item.endAt" format="24hr"
                                                                            :rules="[v => !!v || $t('common.required')]" />
                                                                    </div>

                                                                    <div v-else>
                                                                        <v-time-picker
                                                                            :landscape="$vuetify.breakpoint.smAndUp"
                                                                            v-model="item.endAt" :min="item.startAt"
                                                                            format="24hr"
                                                                            :rules="[v => !!v || $t('common.required')]" />
                                                                    </div>

                                                                </v-col>

                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-col class="justify-center d-flex flex-row">
                                                        <v-btn dark @click="addNewMultiRow">
                                                            {{ $t('service_date_times.add_time') }}
                                                        </v-btn>
                                                    </v-col>
                                                </v-col>
                                            </v-row>
                                        </v-col>





                                    </v-row>

                                    <v-row v-if="selectedPeriodMode == 'customtime'">



                                        <v-col cols="3">
                                            <v-text-field v-model="serviceDateTimeDetailObj.quantity" :rules="[
                                                v => !!v || $t('common.required'),
                                                v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                            ]" :label="$t('common.quantity')" />
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field v-model="serviceDateTimeDetailObj.maxQuantityPerUser" :rules="[
                                                v => !!v || $t('common.required'),
                                                v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                                v => parseInt(v) <= parseInt(serviceDateTimeDetailObj.quantity) || $t('common.valid_maxquantity')
                                            ]" :label="$t('common.max_quantity')" />
                                        </v-col>
                                        <v-col cols="3">
                                            <v-select v-model="minHour" :items="generateSlotLength"
                                                :label="$t('service_date_times.hour_length')"
                                                :rules="[v => !!v || $t('common.required')]" />
                                        </v-col>
                                        <v-col cols="3"></v-col>



                                    </v-row>



                                    <v-row v-if="selectedPeriodMode == 'allcustom'" align="center" justify="start">
                                        <div style="width:10px"></div>

                                        <v-col cols="2">
                                            <v-row align="center" justify="start">
                                                <div v-if="isPerHeadPerHour">
                                                    <h3 style="color: grey;">{{ $t('service_date_times.price_per_hour') }}
                                                    </h3>
                                                </div>
                                                <div v-if="!isPerHeadPerHour">
                                                    <h3 style="color: #82c6fa;">{{ $t('service_date_times.price_per_hour')
                                                    }}
                                                    </h3>
                                                </div>

                                                <v-switch style="color:red;" v-model="isPerHeadPerHour">
                                                    <template v-slot:label>
                                                        <div v-if="isPerHeadPerHour">
                                                            <span style="color:#82c6fa;">{{
                                                                $t('service_date_times.price_per_hour_per_head') }}</span>
                                                        </div>
                                                        <div v-if="!isPerHeadPerHour">
                                                            <span style="color:grey;">{{
                                                                $t('service_date_times.price_per_hour_per_head') }}</span>
                                                        </div>

                                                    </template>
                                                </v-switch>

                                            </v-row>
                                        </v-col>

                                        <v-col cols="2">
                                            <v-select v-model="unitTime" :items="generateUnitTime"
                                                :label="$t('service_date_times.unit_time')"
                                                :rules="[v => !!v || $t('common.required')]" />
                                        </v-col>

                                        <div style="width:10px"></div>

                                        <v-col cols="5">
                                            <v-row align="center" justify="start">
                                                <div v-if="isVariablePricing">
                                                    <h3 style="color: grey;">{{ $t('service_date_times.price_fixed') }}
                                                    </h3>
                                                </div>
                                                <div v-if="!isVariablePricing">
                                                    <h3 style="color: #82c6fa;">{{ $t('service_date_times.price_fixed') }}
                                                    </h3>
                                                </div>

                                                <v-switch style="color:red;" v-model="isVariablePricing">
                                                    <template v-slot:label>
                                                        <div v-if="isVariablePricing">
                                                            <span style="color:#82c6fa;">{{
                                                                $t('service_date_times.price_variable') }}</span>
                                                        </div>
                                                        <div v-if="!isVariablePricing">
                                                            <span style="color:grey;">{{
                                                                $t('service_date_times.price_variable') }}</span>
                                                        </div>

                                                    </template>
                                                </v-switch>
                                                <div style="width:15px"></div>
                                                <div v-if="isVariablePricing == true">
                                                    <v-btn dark @click="addNewVariableRow">
                                                        {{ $t('service_date_times.add_time') }}
                                                    </v-btn>
                                                </div>
                                            </v-row>


                                        </v-col>



                                    </v-row>

                                    <v-row v-if="selectedPeriodMode == 'allcustom'">

                                        <v-col cols="3">
                                            <v-select v-model="minHour" :items="generateSlotLength"
                                                :label="$t('service_date_times.min_hour')"
                                                :rules="[v => !!v || $t('common.required')]" />
                                        </v-col>

                                        <v-col cols="3">
                                            <v-select v-model="maxHour" :items="generateSlotLength"
                                                :label="$t('service_date_times.max_hour')" />
                                        </v-col>

                                        <v-col cols="3">
                                            <v-text-field v-model="serviceDateTimeDetailObj.quantity" :rules="[
                                                v => !!v || $t('common.required'),
                                                v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                            ]" :label="$t('service_date_times.max_people_allowed')" />
                                        </v-col>



                                    </v-row>


                                    <v-row v-if="selectedPeriodMode == 'customtime' || selectedPeriodMode == 'allcustom'">

                                        <v-col cols="12">
                                            <v-card class="px-3 mb-7" v-for="(item, index) in variablePriceRowArr "
                                                :key="index">
                                                <v-card-title>
                                                    {{ $t('service.time') + (index + 1) }}
                                                    <v-spacer />

                                                    <v-row>
                                                        <v-col v-if="selectedPeriodMode == 'customtime'" cols="4">
                                                            <v-text-field v-model="item.price" :rules="[
                                                                v => !!v || $t('common.required'),
                                                                v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                            ]" :label="$t('common.price')" />

                                                        </v-col>

                                                        <v-col v-if="selectedPeriodMode == 'allcustom'" cols="4">
                                                            <div v-if="unitTime == '1.0'">
                                                                <v-text-field v-model="item.price" :rules="[
                                                                    v => !!v || $t('common.required'),
                                                                    v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                                ]"
                                                                    :label="$t('common.price') + '(' + $t('common.whole_hour') + ')'" />
                                                            </div>
                                                            <div v-if="unitTime == '0.5'">
                                                                <v-text-field v-model="item.price" :rules="[
                                                                    v => !!v || $t('common.required'),
                                                                    v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                                ]"
                                                                    :label="$t('common.price') + '(' + $t('common.half_hour') + ')'" />
                                                            </div>

                                                        </v-col>

                                                        <v-col cols="4">
                                                            <v-text-field v-model="item.originalPrice"
                                                                :label="$t('common.original_price')" />
                                                        </v-col>



                                                    </v-row>

                                                    <v-spacer />

                                                    <div v-if="index != 0">
                                                        <v-btn dark @click="removeVariableRow(index)">
                                                            <v-icon>mdi-trash-can-outline</v-icon>
                                                        </v-btn>
                                                    </div>

                                                </v-card-title>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <h2>{{ $t('service.start_at') }}:</h2>
                                                            <v-time-picker :landscape="$vuetify.breakpoint.smAndUp"
                                                                :allowed-minutes="allowedStep" v-model="item.startAt"
                                                                :max="item.endAt" format="24hr"
                                                                :rules="[v => !!v || $t('common.required')]" />
                                                        </v-col>

                                                        <v-col cols="6">
                                                            <v-row align="center" justify="space-between">
                                                                <h2>{{ $t('service.end_at') }}:</h2>
                                                                <v-switch v-model="item.isEndNextDay"
                                                                    :label="$t('service_date_times.end_next_day')" />
                                                                <h2></h2>
                                                            </v-row>
                                                            <div v-if="item.isEndNextDay === true">
                                                                <v-time-picker :landscape="$vuetify.breakpoint.smAndUp"
                                                                    :allowed-minutes="allowedStep" v-model="item.endAt"
                                                                    format="24hr"
                                                                    :rules="[v => !!v || $t('common.required')]" />
                                                            </div>

                                                            <div v-else>
                                                                <v-time-picker :landscape="$vuetify.breakpoint.smAndUp"
                                                                    :allowed-minutes="allowedStep" v-model="item.endAt"
                                                                    :min="item.startAt" format="24hr"
                                                                    :rules="[v => !!v || $t('common.required')]" />
                                                            </div>

                                                        </v-col>



                                                    </v-row>
                                                </v-card-text>
                                            </v-card>

                                        </v-col>


                                    </v-row>

                                </v-col>


                            </v-row>


                            <v-row v-if="action == 'edit'">
                                <v-col cols="6" class="py-0">
                                    <v-switch v-model="serviceDateTimeDetailObj.status"
                                        :label="showStatus(serviceDateTimeDetailObj.status)" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        :value="$moment(serviceDateTimeDetailObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                        disabled :label="$t('common.created_at')" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'

export default {
    props: ['serviceList'],
    components: {
    },
    mounted() { },
    watch: {
        selectedCreateType: function () {
            this.resetDate()
        },
    },
    computed: {
        ...mapState(['lang']),
        i18nCreateTypeList() {
            let result = [
                { text: this.$t('service_date_times.select_multiple_day'), value: 'multiple' },
                { text: this.$t('service_date_times.select_every_weekday'), value: 'range' }
            ]

            return result
        },
        i18nCreatePeriodMode() {
            let result = [
                { text: this.$t('service_date_times.select_fixed_timeslot'), value: 'allfixed' },
                { text: this.$t('service_date_times.select_fixed_duration'), value: 'customtime' },
                { text: this.$t('service_date_times.select_custom_duration'), value: 'allcustom' }
            ]

            return result
        },
        i18nWeekdayList() {
            let result = [
                { text: this.$t('weekdays.sun'), value: '0' },
                { text: this.$t('weekdays.mon'), value: '1' },
                { text: this.$t('weekdays.tue'), value: '2' },
                { text: this.$t('weekdays.wed'), value: '3' },
                { text: this.$t('weekdays.thu'), value: '4' },
                { text: this.$t('weekdays.fri'), value: '5' },
                { text: this.$t('weekdays.sat'), value: '6' }
            ]

            return result
        },
        generateAllDaysTimeSlot() {
            let result = [
                "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30",
                "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
                "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00",
            ]

            return result
        },
        generateSlotLength() {
            let result = []
            if (this.unitTime == "1.0") {
                for (let i = 0; i < 48; i++) {
                    var time1 = (i + 1);
                    result.push(time1.toFixed(1));
                }
            } else {
                for (let i = 0; i < 96; i++) {
                    var time5 = (i + 1) * 0.5;
                    result.push(time5.toFixed(1));
                }
            }

            return result
        },
        generateUnitTime() {
            let result = [
                "0.5", "1.0"
            ]

            return result
        },
        dateTimeResult() {
            let result = []

            if (this.rangeStartDate && this.rangeEndDate && this.selectedWeekDay && this.dateTimeRowArr) {
                let rangeArray = [];

                var startDate = this.$moment(this.rangeStartDate);
                var endDate = this.$moment(this.rangeEndDate);

                while (startDate <= endDate) {
                    rangeArray.push({ day: this.$moment(startDate).format('YYYY-MM-DD'), weekday: this.$moment(startDate).format('d') })
                    startDate = this.$moment(startDate).add(1, 'days');
                }

                rangeArray.map(item => {
                    if (this.selectedWeekDay.includes(item.weekday)) {
                        result.push(item.day)
                    }
                })
            }

            return result
        }
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        allowedStep: m => m % 30 === 0,
        async open(action, item, selectedService) {
            this.serviceDateTimeDetailObj.serviceId = selectedService

            for (const key in this.serviceList) {
                if (this.serviceList[key].value == selectedService) this.serviceDateTimeDetailObj.price = this.serviceList[key].price
            }

            if (action == 'edit') {
                let result = false

                try {
                    result = await api.get('/serviceDateTimes/' + item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) this.serviceDateTimeDetailObj = result.data
            }

            this.action = action
            this.dialog = true
        },
        async save() {

            if (this.selectedPeriodMode == "" || this.selectedCreateType == "") return;

            if (this.selectedPeriodMode == "allfixed") {
                this.saveAllFixed();
            }

            if (this.selectedPeriodMode == "customtime") {
                this.saveCustomTime();
            }

            if (this.selectedPeriodMode == "allcustom") {
                this.saveAllCustom();
            }


        },
        async saveCustomTime() {
            let validated = this.$refs.form.validate();

            if (this.variablePriceRowArr.length != 1) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_single_timslot'),
                    icon: 'mdi-close',
                })

                validated = false
            }

            this.variablePriceRowArr.map(item => {
                if (item.startAt == '' || item.endAt == '') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_select_time'),
                        icon: 'mdi-close',
                    })

                    validated = false
                }
                if (item.originalPrice != "") {
                    if (Number.isInteger(Number(item.originalPrice))) {
                        if (Number(item.originalPrice) < Number(item.price)) {
                            console.log(Number(item.originalPrice))
                            console.log(Number(item.price))
                            this.PUSH_GLOBAL_SNACK_MESSAGES({
                                message: this.$t('global_alert.warning_low_original_price'),
                                icon: 'mdi-close',
                            })
                            validated = false
                        }
                    } else {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_low_original_price'),
                            icon: 'mdi-close',
                        })
                        validated = false
                    }
                }
            })

            if (validated) {
                this.loading = true
                let apiTarget = ''
                let postObj = {}
                this.selectedServiceID = this.serviceDateTimeDetailObj.serviceId

                if (this.selectedCreateType == 'range') this.updatePreviewDate()

                //if(this.selectedCreateType == 'range' || this.selectedDate.length > 1 || this.dateTimeRowArr.length > 1){

                let dateTimesArr = []

                this.selectedDate.map(item => {
                    var timeItem = this.variablePriceRowArr[0];
                    let startDateTimes = this.$moment(`${item} ${timeItem.startAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    let endDateTimes = this.$moment(`${item} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    if (timeItem.isEndNextDay) {
                        var today = new Date(item);
                        var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                        var newitem = nextday.toISOString().substring(0, 10);
                        endDateTimes = this.$moment(`${newitem} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    }

                    dateTimesArr.push({ startAt: this.$moment(startDateTimes).toISOString(), endAt: this.$moment(endDateTimes).toISOString() })
                })

                var isLonger = false;
                var singelStart = new Date(dateTimesArr[0]["startAt"]);
                var singelEnd = new Date(dateTimesArr[0]["endAt"]);
                var totalHour = (singelEnd.getTime() - singelStart.getTime()) / (60 * 60 * 1000);
                if (Number(this.minHour) > totalHour) isLonger = true;
                if (this.maxHour != null) {
                    if (Number(this.maxHour) > totalHour) isLonger = true;
                }

                if (isLonger) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_duration_too_long'),
                        icon: 'mdi-close',
                    })
                    this.loading = false;
                    return;
                }


                var extra = {
                    "mode": this.selectedPeriodMode,
                    "pricing": this.variablePriceRowArr,
                    "minHour": this.minHour,
                    "maxHour": this.maxHour,
                    "unitTime": this.unitTime,
                    "peopleAllowed": this.peopleAllowed,
                    "isPerHeadPerHour": this.isPerHeadPerHour,
                };
                this.serviceDateTimeDetailObj["price"] = this.variablePriceRowArr[0].price;
                this.serviceDateTimeDetailObj["originalPrice"] = Number(this.variablePriceRowArr[0].originalPrice);
                this.serviceDateTimeDetailObj["extra"] = extra;

                apiTarget = '/serviceDateTimes/many'
                postObj = { serviceDateTimeInfo: this.serviceDateTimeDetailObj, dateTimes: dateTimesArr }
                //}

                this.callAPI(apiTarget, postObj);
            }
        },
        async saveAllCustom() {
            let validated = this.$refs.form.validate();


            this.variablePriceRowArr.map(item => {
                if (item.startAt == '' || item.endAt == '') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_select_time'),
                        icon: 'mdi-close',
                    })

                    validated = false
                }
                if (item.originalPrice != "") {
                    if (Number.isInteger(Number(item.originalPrice))) {
                        if (Number(item.originalPrice) < Number(item.price)) {
                            console.log(Number(item.originalPrice))
                            console.log(Number(item.price))
                            this.PUSH_GLOBAL_SNACK_MESSAGES({
                                message: this.$t('global_alert.warning_low_original_price'),
                                icon: 'mdi-close',
                            })
                            validated = false
                        }
                    } else {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_low_original_price'),
                            icon: 'mdi-close',
                        })
                        validated = false
                    }
                }
            })

            if (this.minHour == null || this.maxHour == null || (this.maxHour != null && Number(this.maxHour) < Number(this.minHour))) {

                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_max_min_time'),
                    icon: 'mdi-close',
                })

                validated = false

            }

            if (this.unitTime == "1.0") {
                if (!Number.isInteger(Number(this.minHour))) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_max_min_must_int'),
                        icon: 'mdi-close',
                    })

                    validated = false
                }
                if (this.maxHour != null) {
                    if (!Number.isInteger(Number(this.maxHour))) {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_max_min_must_int'),
                            icon: 'mdi-close',
                        })

                        validated = false
                    }
                }
            }

            var isCon = true;
            for (let i = 1; i < this.variablePriceRowArr.length; i++) {
                const e = this.variablePriceRowArr[i];
                if (e.startAt != this.variablePriceRowArr[i - 1].endAt) {
                    isCon = false;
                }
                if (this.variablePriceRowArr[i - 1].isEndNextDay) {
                    if (!e.isEndNextDay) {
                        isCon = false;
                    }
                }
            }

            if (!isCon) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_continue_timslot'),
                    icon: 'mdi-close',
                })

                validated = false
            }

            if (validated) {
                this.loading = true
                let apiTarget = ''
                let postObj = {}
                this.selectedServiceID = this.serviceDateTimeDetailObj.serviceId;
                this.serviceDateTimeDetailObj.maxQuantityPerUser = this.serviceDateTimeDetailObj.quantity;

                if (this.selectedCreateType == 'range') this.updatePreviewDate()

                //if(this.selectedCreateType == 'range' || this.selectedDate.length > 1 || this.dateTimeRowArr.length > 1){

                let dateTimesArr = []

                this.selectedDate.map(item => {
                    var timeItem = this.variablePriceRowArr[0];
                    var lastItem = this.variablePriceRowArr[this.variablePriceRowArr.length - 1];
                    let startDateTimes = this.$moment(`${item} ${timeItem.startAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    let endDateTimes = this.$moment(`${item} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    if (lastItem.isEndNextDay) {
                        var today = new Date(item);
                        var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                        var newitem = nextday.toISOString().substring(0, 10);
                        endDateTimes = this.$moment(`${newitem} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    }

                    dateTimesArr.push({ startAt: this.$moment(startDateTimes).toISOString(), endAt: this.$moment(endDateTimes).toISOString() })
                })

                var isLonger = false;
                var singelStart = new Date(dateTimesArr[0]["startAt"]);
                var singelEnd = new Date(dateTimesArr[0]["endAt"]);
                var totalHour = (singelEnd.getTime() - singelStart.getTime()) / (60 * 60 * 1000);
                if (Number(this.minHour) > totalHour) isLonger = true;
                if (this.maxHour != null) {
                    if (Number(this.maxHour) > totalHour) isLonger = true;
                }

                if (isLonger) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_duration_too_long'),
                        icon: 'mdi-close',
                    })
                    this.loading = false;
                    return;
                }

                var extra = {
                    "mode": this.selectedPeriodMode,
                    "pricing": this.variablePriceRowArr,
                    "minHour": this.minHour,
                    "maxHour": this.maxHour,
                    "unitTime": this.unitTime,
                    "peopleAllowed": this.peopleAllowed,
                    "isPerHeadPerHour": this.isPerHeadPerHour,
                };
                this.serviceDateTimeDetailObj["price"] = this.variablePriceRowArr[0].price;
                this.serviceDateTimeDetailObj["originalPrice"] = Number(this.variablePriceRowArr[0].originalPrice);
                this.serviceDateTimeDetailObj["extra"] = extra;

                apiTarget = '/serviceDateTimes/many'
                postObj = { serviceDateTimeInfo: this.serviceDateTimeDetailObj, dateTimes: dateTimesArr }
                //}

                this.callAPI(apiTarget, postObj);
            }
        },
        async saveAllFixed() {

            let validated = this.$refs.form.validate();

            this.dateTimeRowArr.map(item => {
                if (item.startAt == '' || item.endAt == '') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_select_time'),
                        icon: 'mdi-close',
                    })

                    validated = false
                }
            })


            if (this.serviceDateTimeDetailObj.originalPrice != "") {
                if (Number.isInteger(Number(this.serviceDateTimeDetailObj.originalPrice))) {
                    if (Number(this.serviceDateTimeDetailObj.originalPrice) < Number(this.serviceDateTimeDetailObj.price)) {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_low_original_price'),
                            icon: 'mdi-close',
                        })
                        validated = false
                    }
                } else {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_low_original_price'),
                        icon: 'mdi-close',
                    })
                    validated = false
                }
            }

            if (validated) {
                this.loading = true
                let apiTarget = ''
                let postObj = {}
                this.selectedServiceID = this.serviceDateTimeDetailObj.serviceId

                if (this.selectedCreateType == 'range') this.updatePreviewDate()

                if (this.selectedCreateType == 'range' || this.selectedDate.length > 1 || this.dateTimeRowArr.length > 1) {

                    let dateTimesArr = []
                    let minTime = null
                    let maxTime = null

                    this.selectedDate.map(item => {
                        this.dateTimeRowArr.map(timeItem => {
                            let startDateTimes = this.$moment(`${item} ${timeItem.startAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                            let endDateTimes = this.$moment(`${item} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                            if (timeItem.isEndNextDay) {
                                var today = new Date(item);
                                var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                                var newitem = nextday.toISOString().substring(0, 10);
                                endDateTimes = this.$moment(`${newitem} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                            }


                            if (this.dateTimeRowArr.length > 1) {
                                let formatedStartTime = Date.parse(startDateTimes)
                                let formatedEndTime = Date.parse(endDateTimes)

                                if (minTime == null || maxTime == null) {
                                    minTime = formatedStartTime
                                    maxTime = formatedEndTime
                                } else {
                                    if (minTime > formatedStartTime) minTime = formatedStartTime
                                    if (minTime > formatedEndTime) minTime = formatedEndTime
                                    if (maxTime < formatedEndTime) maxTime = formatedEndTime
                                    if (maxTime < formatedStartTime) maxTime = formatedStartTime
                                }
                            }

                            dateTimesArr.push({ startAt: this.$moment(startDateTimes).toISOString(), endAt: this.$moment(endDateTimes).toISOString() })
                        })
                    })

                    apiTarget = '/serviceDateTimes/many'
                    postObj = { serviceDateTimeInfo: this.serviceDateTimeDetailObj, dateTimes: dateTimesArr }
                } else {
                    let startAt = this.$moment(`${this.selectedDate[0]} ${this.dateTimeRowArr[0].startAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    let endAt = this.$moment(`${this.selectedDate[0]} ${this.dateTimeRowArr[0].endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    if (this.dateTimeRowArr[0].isEndNextDay) {
                        var today = new Date(this.selectedDate[0]);
                        var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                        var newitem = nextday.toISOString().substring(0, 10);
                        endAt = this.$moment(`${newitem} ${this.dateTimeRowArr[0].endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                    }

                    this.serviceDateTimeDetailObj['startAt'] = this.$moment(startAt).toISOString()
                    this.serviceDateTimeDetailObj['endAt'] = this.$moment(endAt).toISOString()

                    postObj = this.serviceDateTimeDetailObj
                    apiTarget = '/serviceDateTimes'
                }

                this.callAPI(apiTarget, postObj);
            }
        },
        async callAPI(apiTarget, postObj) {

            //console.log(postObj);

            let result = false
            try {

                if (this.serviceDateTimeDetailObj.originalPrice == "") {
                    this.serviceDateTimeDetailObj.originalPrice = 0;
                }
                if (this.action == 'create') {
                    result = await api.post(apiTarget, postObj)
                } else {
                    result = await api.patch('/serviceDateTimes/' + this.serviceDateTimeDetailObj._id, this.serviceDateTimeDetailObj)
                }

                if (result) {
                    let targetI18n = (this.action === 'edit') ? 'global_alert.success_edit' : 'global_alert.success_create'
                    this.resetForm()
                    this.dialog = false
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t(targetI18n),
                        icon: 'mdi-check',
                    })
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }

            this.$emit('refreshService', this.selectedServiceID)

            this.loading = false

        },
        addNewMultiRow() {
            this.dateTimeRowArr.push({ startAt: '', endAt: '', isEndNextDay: false })
        },
        addNewVariableRow() {
            var json = { startAt: '', endAt: '', isEndNextDay: false, price: '', originalPrice: '' };

            this.variablePriceRowArr.splice(this.variablePriceRowArr.length, 0, json)
        },
        removeMultiRow(index) {
            this.dateTimeRowArr.splice(index, 1)
        },
        removeVariableRow(index) {
            this.variablePriceRowArr.splice(index, 1)
        },
        showStatus(status) {
            return (status) ? this.$t('common.enabled') : this.$t('common.disabled')
        },
        close() {
            this.resetForm()
            this.dialog = false
        },
        updatePreviewDate() {
            this.selectedDate = [...this.dateTimeResult]
            this.PreviewDate = true
        },
        resetForm() {
            this.$emit('refresh')
            this.serviceDateTimeDetailObj = {
                serviceId: '',
                price: '',
                originalPrice: '',
                quantity: '',
                maxQuantityPerUser: '',
                published: true
            }
            this.selectedCreateType = ''
            this.selectedPeriodMode = ''
            this.resetDate()
        },
        resetDate() {
            this.PreviewDate = false
            this.rangeStartDate = null
            this.rangeEndDate = null
            this.dateTimeRowArr = [{ startAt: '', endAt: '', isEndNextDay: false }]
            this.variablePriceRowArr = [{ startAt: '', endAt: '', isEndNextDay: false, price: '', originalPrice: '' }]
            this.selectedDate = []
            this.selectedWeekDay = []
        }
    },
    data: () => ({
        dialog: false,
        loading: false,
        startTimeDialog: false,
        endTimeDialog: false,
        CreateTypeList: [
            { text: 'service_date_times.select_multiple_day', value: 'multiple' },
            { text: 'service_date_times.select_every_weekday', value: 'range' }
        ],
        dateTimeRowArr: [
            { startAt: '', endAt: '', isEndNextDay: false }
        ],
        variablePriceRowArr: [
            { startAt: '', endAt: '', isEndNextDay: false, price: '', originalPrice: '' }
        ],
        serviceDateTimeDetailObj: {
            serviceId: '',
            price: '',
            originalPrice: "",
            quantity: '',
            maxQuantityPerUser: '',
            published: true
        },
        minHour: null,
        maxHour: null,
        unitTime: "1.0",
        peopleAllowed: null,
        isPerHeadPerHour: false,
        isVariablePricing: false,
        rangeStartDate: null,
        rangeEndDate: null,
        multipleStartAt: null,
        multipleEndAt: null,
        rangeStartDateDialog: null,
        rangeEndDateDialog: null,
        selectedDate: [],
        action: 'edit',
        selectedWeekDay: '',
        selectedCreateType: '',
        selectedServiceID: '',
        formValid: true,
        PreviewDate: false,
        selectedPeriodMode: ''
    })
}
</script>

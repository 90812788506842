<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="1200">
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('service_date_times.edit') }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form" v-model="formValid" lazy-validation>
                            <v-row>
                                <v-col cols="10">
                                    <v-select v-model="serviceDateTimeDetailObj.serviceId" :items="serviceList"
                                        item-value="value" item-text="text" disabled :label="$t('service.name')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                                <v-col cols="2">
                                    <v-switch v-model="serviceDateTimeDetailObj.published"
                                        :label="showStatus(serviceDateTimeDetailObj.published)" />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-select v-model="selectedPeriodMode" :items="i18nCreatePeriodMode" item-value="value"
                                        item-text="text" :label="$t('service_date_times.create_period')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                            </v-row>

                            <v-row v-if="selectedPeriodMode == 'allfixed'">

                                <v-col cols="2">
                                    <v-text-field v-model="serviceDateTimeDetailObj.price" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                    ]" :label="$t('common.price')" />
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="serviceDateTimeDetailObj.originalPrice"
                                        :label="$t('common.original_price')" />
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="serviceDateTimeDetailObj.quantity" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                        v => parseInt(v) >= parseInt(serviceDateTimeDetailObj.maxQuantityPerUser) || $t('common.valid_maxquantity')
                                    ]" :label="$t('common.quantity')" />
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="serviceDateTimeDetailObj.maxQuantityPerUser" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                        v => parseInt(v) <= parseInt(serviceDateTimeDetailObj.quantity) || $t('common.valid_maxquantity')
                                    ]" :label="$t('common.max_quantity')" />
                                </v-col>

                                <v-col cols="6">
                                    <h2>{{ $t('service.start_at') }}:</h2>
                                    <v-time-picker :landscape="$vuetify.breakpoint.smAndUp" v-model="startTime"
                                        :max="endTime" format="24hr" />
                                </v-col>

                                <v-col cols="6">
                                    <v-row align="center" justify="space-between">
                                        <h2>{{ $t('service.end_at') }}:</h2>
                                        <v-switch v-model="isEndNextDay" :label="$t('service_date_times.end_next_day')" />
                                        <h2></h2>
                                    </v-row>
                                    <div v-if="isEndNextDay === true">
                                        <v-time-picker :landscape="$vuetify.breakpoint.smAndUp" v-model="endTime"
                                            format="24hr" />
                                    </div>

                                    <div v-else>
                                        <v-time-picker :landscape="$vuetify.breakpoint.smAndUp" v-model="endTime"
                                            :min="startTime" format="24hr" />
                                    </div>

                                </v-col>

                            </v-row>

                            <v-row v-if="selectedPeriodMode == 'customtime'">

                                <v-col cols="3">
                                    <v-text-field v-model="serviceDateTimeDetailObj.quantity" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                    ]" :label="$t('common.quantity')" />
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="serviceDateTimeDetailObj.maxQuantityPerUser" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                        v => parseInt(v) <= parseInt(serviceDateTimeDetailObj.quantity) || $t('common.valid_maxquantity')
                                    ]" :label="$t('common.max_quantity')" />
                                </v-col>
                                <v-col cols="3">
                                    <v-select v-model="minHour" :items="generateSlotLength"
                                        :label="$t('service_date_times.hour_length')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                                <v-col cols="3"></v-col>

                            </v-row>

                            <v-row v-if="selectedPeriodMode == 'allcustom'" align="center" justify="start">
                                <div style="width:10px"></div>

                                <v-col cols="2">
                                    <v-row align="center" justify="start">
                                        <div v-if="isPerHeadPerHour">
                                            <h3 style="color: grey;">{{ $t('service_date_times.price_per_hour') }} </h3>
                                        </div>
                                        <div v-if="!isPerHeadPerHour">
                                            <h3 style="color: #82c6fa;">{{ $t('service_date_times.price_per_hour') }} </h3>
                                        </div>

                                        <v-switch style="color:red;" v-model="isPerHeadPerHour">
                                            <template v-slot:label>
                                                <div v-if="isPerHeadPerHour">
                                                    <span style="color:#82c6fa;">{{
                                                        $t('service_date_times.price_per_hour_per_head') }}</span>
                                                </div>
                                                <div v-if="!isPerHeadPerHour">
                                                    <span style="color:grey;">{{
                                                        $t('service_date_times.price_per_hour_per_head') }}</span>
                                                </div>

                                            </template>
                                        </v-switch>

                                    </v-row>
                                </v-col>

                                <v-col cols="2">
                                    <v-select v-model="unitTime" :items="generateUnitTime"
                                        :label="$t('service_date_times.unit_time')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>

                                <div style="width:10px"></div>

                                <v-col cols="5">
                                    <v-row align="center" justify="start">
                                        <div v-if="isVariablePricing">
                                            <h3 style="color: grey;">{{ $t('service_date_times.price_fixed') }} </h3>
                                        </div>
                                        <div v-if="!isVariablePricing">
                                            <h3 style="color: #82c6fa;">{{ $t('service_date_times.price_fixed') }} </h3>
                                        </div>

                                        <v-switch style="color:red;" v-model="isVariablePricing">
                                            <template v-slot:label>
                                                <div v-if="isVariablePricing">
                                                    <span style="color:#82c6fa;">{{ $t('service_date_times.price_variable')
                                                    }}</span>
                                                </div>
                                                <div v-if="!isVariablePricing">
                                                    <span style="color:grey;">{{ $t('service_date_times.price_variable')
                                                    }}</span>
                                                </div>

                                            </template>
                                        </v-switch>
                                        <div style="width:15px"></div>
                                        <div v-if="isVariablePricing == true">
                                            <v-btn dark @click="addNewVariableRow">
                                                {{ $t('service_date_times.add_time') }}
                                            </v-btn>
                                        </div>
                                    </v-row>


                                </v-col>

                            </v-row>

                            <v-row v-if="selectedPeriodMode == 'allcustom'">

                                <v-col cols="3">
                                    <v-select v-model="minHour" :items="generateSlotLength"
                                        :label="$t('service_date_times.min_hour')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>

                                <v-col cols="3">
                                    <v-select v-model="maxHour" :items="generateSlotLength"
                                        :label="$t('service_date_times.max_hour')" />
                                </v-col>

                                <v-col cols="3">
                                    <v-text-field v-model="serviceDateTimeDetailObj.quantity" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number'),
                                    ]" :label="$t('service_date_times.max_people_allowed')" />
                                </v-col>

                            </v-row>

                            <v-row v-if="selectedPeriodMode == 'customtime' || selectedPeriodMode == 'allcustom'">

                                <v-col cols="12">
                                    <v-card class="px-3 mb-7" v-for="(item, index) in variablePriceRowArr " :key="index">
                                        <v-card-title>
                                            {{ $t('service.time') + (index + 1) }}
                                            <v-spacer />

                                            <v-row>
                                                <v-col v-if="selectedPeriodMode == 'customtime'" cols="4">
                                                    <v-text-field v-model="item.price" :rules="[
                                                        v => !!v || $t('common.required'),
                                                        v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                    ]" :label="$t('common.price')" />

                                                </v-col>

                                                <v-col v-if="selectedPeriodMode == 'allcustom'" cols="4">
                                                    <div v-if="unitTime == '1.0'">
                                                        <v-text-field v-model="item.price" :rules="[
                                                            v => !!v || $t('common.required'),
                                                            v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                        ]"
                                                            :label="$t('common.price') + '(' + $t('common.whole_hour') + ')'" />
                                                    </div>
                                                    <div v-if="unitTime == '0.5'">
                                                        <v-text-field v-model="item.price" :rules="[
                                                            v => !!v || $t('common.required'),
                                                            v => /^[0-9][0-9]*$/.test(v) || $t('common.valid_number')
                                                        ]"
                                                            :label="$t('common.price') + '(' + $t('common.half_hour') + ')'" />
                                                    </div>

                                                </v-col>

                                                <v-col cols="4">
                                                    <v-text-field v-model="item.originalPrice"
                                                        :label="$t('common.original_price')" />
                                                </v-col>



                                            </v-row>

                                            <v-spacer />

                                            <div v-if="index != 0">
                                                <v-btn dark @click="removeVariableRow(index)">
                                                    <v-icon>mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </div>

                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="6">
                                                    <h2>{{ $t('service.start_at') }}:</h2>
                                                    <v-time-picker :landscape="$vuetify.breakpoint.smAndUp"
                                                        :allowed-minutes="allowedStep" v-model="item.startAt"
                                                        :max="item.endAt" format="24hr"
                                                        :rules="[v => !!v || $t('common.required')]" />
                                                </v-col>

                                                <v-col cols="6">
                                                    <v-row align="center" justify="space-between">
                                                        <h2>{{ $t('service.end_at') }}:</h2>
                                                        <v-switch v-model="item.isEndNextDay"
                                                            :label="$t('service_date_times.end_next_day')" />
                                                        <h2></h2>
                                                    </v-row>
                                                    <div v-if="item.isEndNextDay === true">
                                                        <v-time-picker :landscape="$vuetify.breakpoint.smAndUp"
                                                            :allowed-minutes="allowedStep" v-model="item.endAt"
                                                            format="24hr" :rules="[v => !!v || $t('common.required')]" />
                                                    </div>

                                                    <div v-else>
                                                        <v-time-picker :landscape="$vuetify.breakpoint.smAndUp"
                                                            :allowed-minutes="allowedStep" v-model="item.endAt"
                                                            :min="item.startAt" format="24hr"
                                                            :rules="[v => !!v || $t('common.required')]" />
                                                    </div>

                                                </v-col>



                                            </v-row>
                                        </v-card-text>
                                    </v-card>

                                </v-col>


                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        :value="$moment(serviceDateTimeDetailObj.createdAt).format('YYYY/MM/DD hh:mm')"
                                        disabled :label="$t('common.created_at')" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        :value="$moment(serviceDateTimeDetailObj.updatedAt).format('YYYY/MM/DD hh:mm')"
                                        disabled :label="$t('common.updated_at')" />
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" @click="save(action)">{{ $t('common.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'

export default {
    components: {
    },
    mounted() {
    },
    computed: {
        ...mapState(['lang']),
        i18nCreatePeriodMode() {
            let result = [
                { text: this.$t('service_date_times.select_fixed_timeslot'), value: 'allfixed' },
                { text: this.$t('service_date_times.select_fixed_duration'), value: 'customtime' },
                { text: this.$t('service_date_times.select_custom_duration'), value: 'allcustom' }
            ]

            return result
        },
        generateAllDaysTimeSlot() {
            let result = [
                "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30",
                "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30",
                "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "24:00",
            ]

            return result
        },
        generateSlotLength() {
            let result = []
            if (this.unitTime == "1.0") {
                for (let i = 0; i < 48; i++) {
                    var time1 = (i + 1);
                    result.push(time1.toFixed(1));
                }
            } else {
                for (let i = 0; i < 96; i++) {
                    var time5 = (i + 1) * 0.5;
                    result.push(time5.toFixed(1));
                }
            }

            return result
        },
        generateUnitTime() {
            let result = [
                "0.5", "1.0"
            ]

            return result
        },
    },

    props: ['serviceList', 'selectedService'],
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        allowedStep: m => m % 30 === 0,
        async open(action, item) {
            if (action == 'edit') {
                let result = false

                try {
                    result = await api.get('/serviceDateTimes/' + item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) {
                    this.serviceDateTimeDetailObj = result.data

                    this.selectedPeriodMode = "allfixed";
                    if (this.serviceDateTimeDetailObj.extra != null) {
                        this.selectedPeriodMode = this.serviceDateTimeDetailObj.extra.mode;
                    }

                    if (this.selectedPeriodMode == "allfixed") {
                        this.fullStartDate = this.$moment(this.serviceDateTimeDetailObj.startAt).format('YYYY-MM-DD')
                        this.fullEndDate = this.$moment(this.serviceDateTimeDetailObj.endAt).format('YYYY-MM-DD')
                        this.startTime = this.$moment(this.serviceDateTimeDetailObj.startAt).format('HH:mm')
                        this.endTime = this.$moment(this.serviceDateTimeDetailObj.endAt).format('HH:mm')
                        this.isEndNextDay = this.fullStartDate != this.fullEndDate
                    } else {
                        this.variablePriceRowArr = this.serviceDateTimeDetailObj.extra.pricing;
                        this.minHour = this.serviceDateTimeDetailObj.extra.minHour;
                        this.maxHour = this.serviceDateTimeDetailObj.extra.maxHour;
                        this.unitTime = this.serviceDateTimeDetailObj.extra.unitTime;
                        console.log(this.serviceDateTimeDetailObj.extra.unitTime)
                        console.log(this.unitTime)
                        this.peopleAllowed = this.serviceDateTimeDetailObj.extra.peopleAllowed;
                        this.isPerHeadPerHour = this.serviceDateTimeDetailObj.extra.isPerHeadPerHour;
                    }

                }
            }

            this.action = action
            this.dialog = true
        },

        async save() {

            if (this.selectedPeriodMode == "") return;

            if (this.selectedPeriodMode == "allfixed") {
                this.saveAllFixed();
            }

            if (this.selectedPeriodMode == "customtime") {
                this.saveCustomTime();
            }

            if (this.selectedPeriodMode == "allcustom") {
                this.saveAllCustom();
            }


        },
        async saveCustomTime() {
            let validated = this.$refs.form.validate();

            if (this.variablePriceRowArr.length != 1) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_single_timslot'),
                    icon: 'mdi-close',
                })

                validated = false
            }

            this.variablePriceRowArr.map(item => {
                if (item.startAt == '' || item.endAt == '') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_select_time'),
                        icon: 'mdi-close',
                    })

                    validated = false
                }
                if (item.originalPrice != "") {
                    if (Number.isInteger(Number(item.originalPrice))) {
                        if (Number(item.originalPrice) < Number(item.price)) {
                            console.log(Number(item.originalPrice))
                            console.log(Number(item.price))
                            this.PUSH_GLOBAL_SNACK_MESSAGES({
                                message: this.$t('global_alert.warning_low_original_price'),
                                icon: 'mdi-close',
                            })
                            validated = false
                        }
                    } else {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_low_original_price'),
                            icon: 'mdi-close',
                        })
                        validated = false
                    }
                }
            })

            if (validated) {
                this.loading = true
                this.selectedServiceID = this.serviceDateTimeDetailObj.serviceId

                let dateTimesArr = []


                var nonlocale = new Date(this.$moment(this.serviceDateTimeDetailObj.startAt).toISOString())
                var locale = new Date(nonlocale.getTime() + 8 * 60 * 60 * 1000)
                var item = locale.toISOString().substring(0, 10)

                var timeItem = this.variablePriceRowArr[0];
                let startDateTimes = this.$moment(`${item} ${timeItem.startAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                let endDateTimes = this.$moment(`${item} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                if (timeItem.isEndNextDay) {
                    var today = new Date(item);
                    var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                    var newitem = nextday.toISOString().substring(0, 10);
                    endDateTimes = this.$moment(`${newitem} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                }

                dateTimesArr.push({ startAt: this.$moment(startDateTimes).toISOString(), endAt: this.$moment(endDateTimes).toISOString() })


                this.serviceDateTimeDetailObj.startAt = dateTimesArr[0]["startAt"]
                this.serviceDateTimeDetailObj.endAt = dateTimesArr[dateTimesArr.length - 1]["endAt"]

                var isLonger = false;
                var singelStart = new Date(dateTimesArr[0]["startAt"]);
                var singelEnd = new Date(dateTimesArr[0]["endAt"]);
                var totalHour = (singelEnd.getTime() - singelStart.getTime()) / (60 * 60 * 1000);
                if (Number(this.minHour) > totalHour) isLonger = true;
                if (this.maxHour != null) {
                    if (Number(this.maxHour) > totalHour) isLonger = true;
                }

                if (isLonger) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_duration_too_long'),
                        icon: 'mdi-close',
                    })
                    this.loading = false;
                    return;
                }


                var extra = {
                    "mode": this.selectedPeriodMode,
                    "pricing": this.variablePriceRowArr,
                    "minHour": this.minHour,
                    "maxHour": this.maxHour,
                    "unitTime": this.unitTime,
                    "peopleAllowed": this.peopleAllowed,
                    "isPerHeadPerHour": this.isPerHeadPerHour,
                };
                this.serviceDateTimeDetailObj["price"] = this.variablePriceRowArr[0].price;
                this.serviceDateTimeDetailObj["originalPrice"] = Number(this.variablePriceRowArr[0].originalPrice);
                this.serviceDateTimeDetailObj["extra"] = extra;

                this.callAPI();
            }
        },
        async saveAllCustom() {
            let validated = this.$refs.form.validate();


            this.variablePriceRowArr.map(item => {
                if (item.startAt == '' || item.endAt == '') {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_select_time'),
                        icon: 'mdi-close',
                    })

                    validated = false
                }
                if (item.originalPrice != "") {
                    if (Number.isInteger(Number(item.originalPrice))) {
                        if (Number(item.originalPrice) < Number(item.price)) {
                            console.log(Number(item.originalPrice))
                            console.log(Number(item.price))
                            this.PUSH_GLOBAL_SNACK_MESSAGES({
                                message: this.$t('global_alert.warning_low_original_price'),
                                icon: 'mdi-close',
                            })
                            validated = false
                        }
                    } else {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_low_original_price'),
                            icon: 'mdi-close',
                        })
                        validated = false
                    }
                }
            })

            if (this.minHour == null || this.maxHour == null || (this.maxHour != null && Number(this.maxHour) < Number(this.minHour))) {

                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_max_min_time'),
                    icon: 'mdi-close',
                })

                validated = false

            }

            if (this.unitTime == "1.0") {
                if (!Number.isInteger(Number(this.minHour))) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_max_min_must_int'),
                        icon: 'mdi-close',
                    })

                    validated = false
                }
                if (this.maxHour != null) {
                    if (!Number.isInteger(Number(this.maxHour))) {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_max_min_must_int'),
                            icon: 'mdi-close',
                        })

                        validated = false
                    }
                }
            }

            var isCon = true;
            for (let i = 1; i < this.variablePriceRowArr.length; i++) {
                const e = this.variablePriceRowArr[i];
                if (e.startAt != this.variablePriceRowArr[i - 1].endAt) {
                    isCon = false;
                }
                if (this.variablePriceRowArr[i - 1].isEndNextDay) {
                    if (!e.isEndNextDay) {
                        isCon = false;
                    }
                }
            }

            if (!isCon) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_continue_timslot'),
                    icon: 'mdi-close',
                })

                validated = false
            }

            if (validated) {
                this.loading = true
                this.serviceDateTimeDetailObj.maxQuantityPerUser = this.serviceDateTimeDetailObj.quantity;

                let dateTimesArr = []

                var nonlocale = new Date(this.$moment(this.serviceDateTimeDetailObj.startAt).toISOString())
                var locale = new Date(nonlocale.getTime() + 8 * 60 * 60 * 1000)
                var item = locale.toISOString().substring(0, 10)

                var timeItem = this.variablePriceRowArr[0];
                var lastItem = this.variablePriceRowArr[this.variablePriceRowArr.length - 1];
                let startDateTimes = this.$moment(`${item} ${timeItem.startAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                let endDateTimes = this.$moment(`${item} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                if (lastItem.isEndNextDay) {
                    var today = new Date(item);
                    var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                    var newitem = nextday.toISOString().substring(0, 10);
                    endDateTimes = this.$moment(`${newitem} ${timeItem.endAt}`, 'YYYY-MM-DD HH:mm:ss').format();
                }

                dateTimesArr.push({ startAt: this.$moment(startDateTimes).toISOString(), endAt: this.$moment(endDateTimes).toISOString() })


                this.serviceDateTimeDetailObj.startAt = dateTimesArr[0]["startAt"]
                this.serviceDateTimeDetailObj.endAt = dateTimesArr[dateTimesArr.length - 1]["endAt"]

                var isLonger = false;
                var singelStart = new Date(dateTimesArr[0]["startAt"]);
                var singelEnd = new Date(dateTimesArr[0]["endAt"]);
                var totalHour = (singelEnd.getTime() - singelStart.getTime()) / (60 * 60 * 1000);
                if (Number(this.minHour) > totalHour) isLonger = true;
                if (this.maxHour != null) {
                    if (Number(this.maxHour) > totalHour) isLonger = true;
                }

                if (isLonger) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_duration_too_long'),
                        icon: 'mdi-close',
                    })
                    this.loading = false;
                    return;
                }



                var extra = {
                    "mode": this.selectedPeriodMode,
                    "pricing": this.variablePriceRowArr,
                    "minHour": this.minHour,
                    "maxHour": this.maxHour,
                    "unitTime": this.unitTime,
                    "peopleAllowed": this.peopleAllowed,
                    "isPerHeadPerHour": this.isPerHeadPerHour,
                };
                this.serviceDateTimeDetailObj["price"] = this.variablePriceRowArr[0].price;
                this.serviceDateTimeDetailObj["originalPrice"] = Number(this.variablePriceRowArr[0].originalPrice);
                this.serviceDateTimeDetailObj["extra"] = extra;



                this.callAPI();
            }
        },
        async saveAllFixed() {

            let validated = this.$refs.form.validate();

            if (this.serviceDateTimeDetailObj.originalPrice != "") {
                if (Number.isInteger(Number(this.serviceDateTimeDetailObj.originalPrice))) {
                    if (Number(this.serviceDateTimeDetailObj.originalPrice) < Number(this.serviceDateTimeDetailObj.price)) {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_low_original_price'),
                            icon: 'mdi-close',
                        })
                        validated = false
                    }
                } else {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.warning_low_original_price'),
                        icon: 'mdi-close',
                    })
                    validated = false
                }
            }

            if (validated) {
                this.loading = true

                let startDateTimes = this.$moment(`${this.fullStartDate} ${this.startTime}`, 'YYYY-MM-DD HH:mm:ss').format();
                let endDateTimes = this.$moment(`${this.fullStartDate} ${this.endTime}`, 'YYYY-MM-DD HH:mm:ss').format();
                if (this.isEndNextDay) {
                    var today = new Date(this.fullStartDate);
                    var nextday = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                    var newitem = nextday.toISOString().substring(0, 10);
                    endDateTimes = this.$moment(`${newitem} ${this.endTime}`, 'YYYY-MM-DD HH:mm:ss').format();
                }

                this.serviceDateTimeDetailObj.startAt = this.$moment.utc(startDateTimes).toISOString()
                this.serviceDateTimeDetailObj.endAt = this.$moment.utc(endDateTimes).toISOString()
                this.serviceDateTimeDetailObj.extra = null;

                this.callAPI();
            }
        },

        async callAPI() {

            //console.log(postObj);

            let result = false
            try {

                if (this.serviceDateTimeDetailObj.originalPrice == "") {
                    this.serviceDateTimeDetailObj.originalPrice = 0;
                }

                result = await api.patch('/serviceDateTimes/' + this.serviceDateTimeDetailObj._id, this.serviceDateTimeDetailObj)

                if (result) {
                    let targetI18n = (this.action === 'edit') ? 'global_alert.success_edit' : 'global_alert.success_create'
                    this.resetForm()
                    this.dialog = false
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t(targetI18n),
                        icon: 'mdi-check',
                    })
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
            }

            this.$emit('refreshService', this.selectedService)

            this.loading = false

        },
        showStatus(status) {
            return status ? this.$t('common.enabled') : this.$t('common.disabled')
        },
        close() {
            this.resetForm()
            this.dialog = false
        },
        resetForm() {
            this.$emit('refresh')
            this.serviceDateTimeDetailObj = {}
            this.fullStartDate = null
            this.fullEndDate = null
            this.startTime = null
            this.endTime = null
            this.isEndNextDay = false
        },
    },
    data: () => ({
        dialog: false,
        serviceDateTimeDetailObj: {},
        action: 'edit',
        fullStartDate: null,
        fullEndDate: null,
        formValid: true,
        openMenu: false,
        startTime: null,
        endTime: null,
        closeMenu: false,
        isEndNextDay: false,
        selectedPeriodMode: '',
        minHour: null,
        maxHour: null,
        unitTime: "1.0",
        peopleAllowed: null,
        isPerHeadPerHour: false,
        isVariablePricing: false,
        variablePriceRowArr: [
            { startAt: '', endAt: '', isEndNextDay: false, price: '', originalPrice: '' }
        ],
    })
}
</script>
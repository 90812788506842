<template>
  <v-container fluid class="mt-8">
        <v-row :class="(permissions != 'admin')? 'mb-5' : 'mb-0'">
            <v-col cols="6" v-if="permissions == 'admin'">
                <v-autocomplete
                    v-model="selectedMerchant"
                    :items="merchantsList"
                    item-value="_id"
                    item-text="name"
                    :label="$t('merchant.name')"
                    solo
                    dense
                    clearable
                />
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn dark @click="create">
                    {{$t('service.create')}}
                </v-btn>
            </v-col>
            <v-col class="d-flex justify-end" v-if="permissions == 'admin'">
                <v-btn dark :loading="excelLoading">
                <JsonExcel
                    name="PlaysmartService.xls"
                    :fetch="downoloadExcel"
                >
                    {{ $t('service.export_data') }}
                </JsonExcel>
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            mobile-breakpoint=0
            class="elevation-4"
            :loading="loading"
            :headers="i18nHeader"
            :items="serviceList"
            :options.sync="options"
            :custom-sort="customSort"
            :server-items-length="totalService"
            :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, 100]}"
        >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>{{ (item.name)? item.name : '-' }} </td>
                    <!-- <td>{{ handledCategories(item.category) }} </td> -->
                    <td>{{ item.merchantName }} </td>
                    <td>{{ handledTeacher(item.teacher) }} </td>

                    <td v-if="permissions == 'merchant'"> {{ $moment(item.startAt).format('YYYY/MM/DD HH:mm') + ' ~ ' + $moment(item.endAt).format('YYYY/MM/DD HH:mm') }}</td>
                    <td v-if="permissions == 'merchant'"> {{ $moment(item.createdAt).format('YYYY/MM/DD HH:mm') }}</td>

                    <td  v-if="permissions == 'admin'"> {{ $moment(item.startAt).format('YYYY/MM/DD HH:mm')  }}</td>
                    <td  v-if="permissions == 'admin'"> {{  $moment(item.endAt).format('YYYY/MM/DD HH:mm') }}</td>
                    
                    <td v-if="permissions == 'admin' && $moment(item.sdtEndAt).isBefore($moment().toISOString())" style="color:red"> {{ $moment(item.sdtEndAt).format('YYYY/MM/DD HH:mm') }}</td>
                    <td v-if="permissions == 'admin' && $moment(item.sdtEndAt).isAfter($moment().toISOString())" style="color:black"> {{ $moment(item.sdtEndAt).format('YYYY/MM/DD HH:mm') }}</td>
                    <td>
                        <v-btn small :color="(item.published)? 'success': 'error'" @click="changeStatus(item, index)"> <v-icon left>mdi-{{(item.published)? 'check': 'close'}}</v-icon> {{showStatus(item.published)}}</v-btn>
                    </td>
                    <td>
                        <v-icon @click="openDateTime(item)">
                            mdi-calendar-clock
                        </v-icon>
                    </td>
                    <td>
                        <v-icon @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </td>
                    <td>
                        <v-icon @click="copy(item, index)">
                            mdi-content-copy
                        </v-icon>
                    </td>
                </tr>
                </template>
        </v-data-table>
        <ServiceDetailDialog
            ref="dialog"
            @refresh="getAllService"
            :merchantsList="merchantsList"
            :teacherList="teacherList"
            :categoryList="categoryList"
            :areasList="handledAreaList"
        />
        <ServiceDateTimeDialog
            ref="dateTimeDialog"
        />
        <confirmDialog ref="confirmDialog"/>
  </v-container>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import ServiceDetailDialog from "@/views/Service/ServiceDetailDialog"
import ServiceDateTimeDialog from "@/views/Service/ServiceDateTimeDialog"
import confirmDialog from "@/components/ConfirmDialog"
import api from '@/api'
import JsonExcel                from "vue-json-excel"

export default {
    components:{
        ServiceDetailDialog,
        confirmDialog,
        ServiceDateTimeDialog,
        JsonExcel,
    },
    mounted(){
        // this.getAllService()
        this.getAllCategories()
        this.getAllMerchants()
        this.getAllTeacher()
        this.getAllAreas()
    },
    computed:{
        ...mapState(['lang', 'permissions', 'stateMerchantName']),
        i18nHeader(){
            let result = this.headers

            if(this.permissions == "merchant"){
                result = this.headersMerchant;
            }

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        handledAreaList(){
            let result = []
            if(this.areasList && this.areasList != ''){
                let areaArr = this.areasList['area']
                let location = this.areasList['subArea']

                areaArr.map( item => {
                    result.push({
                        'region': item[(this.lang == 'tc')? 'nameZh' : 'nameEng'],
                        'location': location[item.alias]
                    })
                })
            }
            return result
        }
    },
    watch: {
        options: {
            handler () {
                this.getAllService()
            }
        },
        selectedMerchant: {
            handler () {
                this.getAllService()
            }
        },
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async getAllService() {
            this.loading = true

            if(this.$route.query && this.$route.query.id){
                this.selectedMerchant = this.$route.query.id
                this.$route.query.id = ''
            }

            try{
                var {sortBy, sortDesc, page, itemsPerPage} = this.options
                let order = (sortDesc[0])? -1 : 1
                let params = {
                    page: page,
                    perPage: itemsPerPage,
                    sort: sortBy,
                    order: order,
                }

                if(this.permissions == 'admin'){
                    params = {
                        page: page,
                        perPage: itemsPerPage,
                        sort: "endAt",
                        order: -1,
                    }
                }

                if(this.selectedMerchant != '') Object.assign( params ,{merchantId: this.selectedMerchant} )

                let apiTarget = (this.permissions == 'admin') ? '/services/adminall' : '/services'

                let result = await api.get(apiTarget, {params})

                if(result){
                    this.serviceList = result.data.docs
                    this.totalService = result.data.totalDocs

                    if (sortBy.length === 1 || sortDesc.length === 1) {
                        this.serviceList = this.serviceList.sort((a, b) => {
                            const sortA = a[sortBy[0]]
                            const sortB = b[sortBy[0]]

                            if (sortDesc[0]) {
                                if (sortA < sortB) return 1
                                if (sortA > sortB) return -1
                                return 0
                            } else {
                                if (sortA < sortB) return -1
                                if (sortA > sortB) return 1
                                return 0
                            }
                        })
                    }
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        async getAllCategories() {
            let apiTarget = (this.permissions == 'admin')? '/category' : '/category/published'

            try{
                let result = await api.get(apiTarget)

                if(result){
                    this.categoryList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllMerchants() {
            let apiTarget = (this.permissions == 'admin')? '/merchants/all' : '/merchants/published'

            try{
                let result = await api.get(apiTarget)

                if(result){
                    this.merchantsList = (this.permissions == 'admin')? result.data : result.data.docs
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllTeacher() {
            this.loading = true
            try{
                let result = await api.get('/teacher')

                if(result){
                    this.teacherList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        async getAllAreas() {
            try{
                let result = await api.get('/setting/areas')

                if(result){
                    this.areasList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async changeStatus(item, index){
            let action = await this.$refs.confirmDialog.open({
                title  : this.$t('service.update_status'),
                content: this.$t('service.qa_update_status') +  item.name + `${(item.published)? this.$t('service.qa_update_status_ban') : this.$t('service.qa_update_status_active')}?`,
                })

            if (action) {
                let result = false

                item.published = (item.published)? false : true

                try {
                    result = await api.patch('/services/' + item._id, item)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                if (result) {
                    this.serviceList[index].status = item.status
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_update_status'),
                        icon   : 'mdi-check',
                    })
                }
            }
            this.getAllService()
        },
        handledCategories(category){
            let findedCat = category

            if(category && this.categoryList.length > 0){
                this.categoryList.map(item => {
                    if(item._id == category) findedCat = item[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
                })
            }

            return findedCat
        },
        handledTeacher(teacher){
            let result = teacher

            if(teacher && this.teacherList.length > 0){
                this.teacherList.map(item => {
                    if(item._id == teacher) result = item[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
                })
            }

            return result
        },
        handledMerchantID(id){
            let result = id

            if(id && this.merchantsList.length > 0){
                result = this.merchantsList.find(item => item._id == id)['name']
            }

            return result
        },
        openDateTime(item){
            this.$refs.dateTimeDialog.open(item._id)
        },
        create(){
            this.$refs.dialog.open('create', '')
        },
        edit(item){
            this.$refs.dialog.open('edit', item)
        },
        showStatus(published){
            return (published)? this.$t('common.enabled'):this.$t('common.disabled');
        },
        customSort(
            items,
            sortBy,
            sortDesc,
            locale,
            customSorters
            ) {
                /*
                :sort-by="['name','email','phone','createdAt', 'endAt', 'status']"
                :sort-desc="[false, true]"
                */
                console.log(sortBy);
               console.log(customSorters);
               console.log(sortDesc);
            return items.sort((a, b) => a.name.localeCompare(b.name, locale));
                
        },
        async copy(item){
            let action = await this.$refs.confirmDialog.open({
                title  : this.$t('service.copy_service'),
                content: this.$t('service.copy_content') ,
                })

                console.log(item);
            
            if (action) {
                let result = false

                try {
                    result = await api.get('/services/clone/'+item["_id"])
                    console.log(result);
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                if (result) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_create'),
                        icon   : 'mdi-check',
                    })
                }
            }
            this.getAllService()
            
        },
        async downoloadExcel () {
            this.excelLoading = true
            let result = ''
            let resultArr = []
            let tmpObj = {}

            try {
                result = await api.get('/services/adminlist')

                result = result.data

                result.forEach(item => {
                tmpObj = {
                    "id": item._id,
                    "name": item.name ?? "",
                    "merchant": item.merchantId.name ?? "",
                    "teacher": this.handledTeacher(item.teacher),
                    "startAt": this.$moment(item.startAt).format('YYYY/MM/DD HH:mm'),
                    "endAt": this.$moment(item.endAt).format('YYYY/MM/DD HH:mm'),
                    "sdtEndAt": this.$moment(item.sdtEndAt).format('YYYY/MM/DD HH:mm'),
                    "status": (item.published)? 'success': 'error',
                }
                

                    if (tmpObj != '') resultArr.push(tmpObj)
                });
            } catch (error) {
                //console.log(error);
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                message : error.response.data.message,
                icon    : 'mdi-close',
                })
            }
            this.excelLoading = false
            console.log(resultArr);
            return resultArr;
            },
    },
    data: () => ({
        headers: [
            {i18n: 'service.name',value: 'name'},
            // {i18n: 'merchant.categories',value: 'category'},
            {i18n: 'common.merchant',value: 'merchantId'},
            {i18n: 'common.teacher',value: 'teacher'},
            {i18n: 'service.start_at',value: 'startAt'},
            {i18n: 'service.end_at',value: 'endAt'},
            {i18n: 'service.service_last_end_at', value: 'sdtEndAt'},
            {i18n: 'common.status', value: 'published'},
            {i18n: 'page_name.service_date_times', value: '', sortable: false},
            {i18n: 'common.edit', value: '', sortable: false },
            {i18n: 'common.copy', value: '', sortable: false },
        ],
        headersMerchant: [
        {i18n: 'service.name',value: 'name'},
            // {i18n: 'merchant.categories',value: 'category'},
            {i18n: 'common.merchant',value: 'merchantId'},
            {i18n: 'common.teacher',value: 'teacher'},
            {i18n: 'service.time',value: '', sortable: false},
            {i18n: 'common.created_at', value: 'createdAt'},
            {i18n: 'common.status', value: 'published'},
            {i18n: 'page_name.service_date_times', value: '', sortable: false},
            {i18n: 'common.edit', value: '', sortable: false },
            {i18n: 'common.copy', value: '', sortable: false },
        ],
        searchByName: '',
        searchByEmail: '',
        serviceList: [],
        merchantsList: [],
        teacherList: [],
        categoryList: [],
        areasList:[],
        loading: false,
        options: {},
        totalService: 0,
        selectedMerchant: null,
        excelLoading: false,
    })
}
</script>

<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="1200">
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('service.' + action) }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="form" v-model="formValid" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="serviceDetailObj.name"
                                        :rules="[v => !!v || $t('common.required')]" :label="$t('service.name')" />
                                </v-col>
                                <v-col cols="6" v-if="permissions == 'admin'">
                                    <v-combobox v-model="serviceDetailObj.merchantId" :items="merchantsList"
                                        item-value="_id" item-text="name" :label="$t('merchant.name')"
                                        :rules="[v => !!v || $t('common.required')]" outlined />
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="serviceDetailObj.teacher" :items="filteredTeacherList"
                                        item-value="_id" item-text="name" :label="$t('service.teacher')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                                <v-col cols="12" v-if="action == 'edit'">
                                    <v-switch v-model="serviceDetailObj.published"
                                        :label="showStatus(serviceDetailObj.published)" />
                                </v-col>

                                <v-col cols="3">
                                    <v-text-field v-model="serviceDetailObj.price" :label="$t('common.price')" :rules="[
                                        v => !!v || $t('common.required')
                                    ]" />
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="serviceDetailObj.originalPrice"
                                        :label="$t('common.original_price')" />

                                </v-col>
                                <v-col cols="3" v-if="permissions == 'admin'">
                                    <v-text-field v-model="serviceDetailObj.promotionText"
                                        :label="$t('common.promotion_text')" />
                                </v-col>
                                <v-col cols="6">
                                    <date-picker v-if="editDate" v-model="timeArr" type="datetime" clearable
                                        value-type="format" format="YYYY/MM/DD HH:mm" range
                                        :placeholder="$t('service.time')" />
                                    <v-text-field v-else v-model="startAtEndAt" :label="$t('service.time')" readonly
                                        @click="editDate = true" />
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="serviceDetailObj.category" :items="categoryList" item-value="_id"
                                        :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'" :label="$t('service.category')"
                                        :rules="[v => !!v || $t('common.required')]" />
                                </v-col>
                                <v-col cols="12" v-if="serviceDetailObj.category == '61c2be8891779a388cef5dfd'">
                                    <v-select multiple v-model="serviceDetailObj.facilities" :items="facilityList"
                                        item-value="id" :item-text="(lang == 'tc') ? 'textZh' : 'textEng'"
                                        :label="$t('setting.facilities')" />

                                </v-col>
                                <v-col cols="12" v-if="serviceDetailObj.category == '61c2beee91779a388cef5e1b'">
                                    <v-select multiple v-model="serviceDetailObj.aspects" :items="aspectList"
                                        item-value="id" :item-text="(lang == 'tc') ? 'textZh' : 'textEng'"
                                        :label="$t('setting.aspects')" />


                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="serviceDetailObj.descriptionShort"
                                        :label="$t('service.descriptionShort')" rows="2" />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="serviceDetailObj.descriptionLong"
                                        :label="$t('service.descriptionLong')" rows="2" />
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea v-model="serviceDetailObj.objectives" :label="$t('service.objectives')"
                                        rows="2" />
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea v-model="serviceDetailObj.precautions" :label="$t('service.precautions')"
                                        rows="2" />
                                </v-col>
                                <!-- <v-col cols="6" >
                                    <multiselect
                                        v-model="serviceDetailObj.area"
                                        :options="areasList"
                                        group-label="region"
                                        group-values="location"
                                        :multiple="false"
                                        :group-select="false"
                                        :placeholder="$t('common.area')"
                                        track-by="alias"
                                        :label="(lang == 'tc')? 'nameZh' : 'nameEng'"
                                    >
                                        <span slot="noResult">{{$t('global_alert.area_no_result')}}</span>
                                    </multiselect>
                                </v-col> -->
                                <!-- <v-col cols="12" >
                                    <v-text-field
                                        v-model="serviceDetailObj.address"
                                        :label="$t('common.address')"
                                    />
                                </v-col> -->
                                <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('service.image') }}</v-card-title>
                                        <v-card-text>
                                            <draggable v-if="serviceDetailObj.image" v-model="serviceDetailObj.image"
                                                @start="drag = true" @end="drag = false" tag="div" class="row"
                                                draggable=".draggableImage">
                                                <v-col v-for="(image, index) in serviceDetailObj.image" :key="image._id"
                                                    class="d-flex child-flex draggableImage" cols="2" style="height:235px">
                                                    <v-hover>
                                                        <template v-slot:default="{ hover }">
                                                            <v-card>
                                                                <v-img max-height="150" v-if="image.large"
                                                                    :src="image.large.path" aspect-ratio="1"
                                                                    class="grey lighten-2">
                                                                    <template v-slot:placeholder>
                                                                        <v-row class="fill-height ma-0" align="center"
                                                                            justify="center">
                                                                            <v-progress-circular indeterminate
                                                                                color="grey lighten-5" />
                                                                        </v-row>
                                                                    </template>
                                                                </v-img>
                                                                <v-fade-transition>
                                                                    <v-overlay v-if="hover" absolute color="#036358">
                                                                        drag to re-odering
                                                                    </v-overlay>
                                                                </v-fade-transition>
                                                                <v-card-actions>
                                                                    <v-btn v-if="permissions == 'admin'" style="z-index:999"
                                                                        icon @click="copyImage(index)">
                                                                        <v-icon>mdi-content-copy</v-icon>
                                                                    </v-btn>
                                                                    <v-spacer />
                                                                    <v-btn style="z-index:999" icon
                                                                        @click="removeImage(index)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </template>
                                                    </v-hover>
                                                </v-col>
                                            </draggable>
                                            <v-col cols="12" key="newImage">
                                                <v-card style="height:100%;">
                                                    <v-container>
                                                        <file-pond name="upload_image" ref="pond"
                                                            :label-idle="$t('img_label')"
                                                            accepted-file-types="image/jpeg, image/png"
                                                            :files="uploadImageFile" allow-multiple="false"
                                                            max-file-size="5MB" @processfile="removeFile" />
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <!-- <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('service.image') }}</v-card-title>
                                        <v-card-text>
                                            <v-img
                                                v-if="serviceDetailObj.image && serviceDetailObj.image.large && serviceDetailObj.image.large.path"
                                                class="mx-auto"
                                                :src="serviceDetailObj.image.large.path"
                                                max-width="500"
                                            />
                                            <file-pond
                                                v-else
                                                name="upload"
                                                ref="pond"
                                                :label-idle="$t('img_label')"
                                                accepted-file-types="image/jpeg, image/png"
                                                :files="uploadImageFile"
                                                allow-multiple="true"
                                                max-file-size="5MB"
                                                v-on:removefile="serviceDetailObj.image = ''"
                                            />
                                        </v-card-text>
                                        <v-card-actions v-if="serviceDetailObj.image && serviceDetailObj.image.large && serviceDetailObj.image.large.path">
                                            <v-spacer/>
                                                <v-btn @click="serviceDetailObj.image = ''" text>
                                                    <v-icon>mdi-pencil</v-icon>
                                                </v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </v-card>
                                </v-col> -->
                                <v-col cols="12" v-if="action == 'edit'">
                                    <v-text-field :value="$moment(serviceDetailObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                        disabled :label="$t('common.created_at')" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" @click="save(action)">{{ $t('common.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import api from '@/api'
import draggable from 'vuedraggable'

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
    props: ['merchantsList', 'teacherList', 'categoryList', 'areasList'],
    components: {
        DatePicker,
        FilePond,
        draggable
    },
    mounted() {
        let self = this
        this.setOptions({
            server: {
                process: async function (fieldName, files, metadata, load, error, progress, abort) {
                    try {
                        let formData = new FormData()
                        formData.append('file', files)

                        let result = await api.post('/image', formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })

                        if (result) {
                            self.serviceDetailObj.image.push(result.data);
                        }
                        load(true)
                    } catch (err) {
                        console.log(err.message);
                        abort(true)
                    }
                }
            },
            imageResizeMode: 'contain',
        })
    },
    computed: {
        ...mapState(['lang', 'permissions', 'stateMerchantId', 'stateMerchantName']),
        filteredTeacherList() {
            let result = []

            if (this.serviceDetailObj.merchantId) {
                let merchantId = (this.permissions == 'admin') ? this.serviceDetailObj.merchantId._id : this.serviceDetailObj.merchantId
                this.teacherList.map(item => {
                    if (item.merchantId == merchantId) {
                        result.push({ name: item[(this.lang == 'tc') ? 'nameZh' : 'nameEng'], _id: item._id })
                    }
                })
            }

            return result
        },
        startAtEndAt() {
            let result = ''

            if (this.serviceDetailObj.startAt && this.serviceDetailObj.endAt && this.action == 'edit') {
                result = this.$moment(this.serviceDetailObj.startAt).format('YYYY/MM/DD HH:mm') + ' ~ ' + this.$moment(this.serviceDetailObj.endAt).format('YYYY/MM/DD HH:mm')
            }

            return result
        }
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(action, item) {
            if (action == 'edit') {
                let result = false

                try {
                    result = await api.get('/services/' + item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) {
                    this.serviceDetailObj = result.data
                    this.serviceDetailObj.category = result.data["category"] == null ? "" : result.data.category["_id"];
                }


                this.timeArr[0] = this.$moment(this.serviceDetailObj.startAt).format('YYYY/MM/DD HH:mm')
                this.timeArr[1] = this.$moment(this.serviceDetailObj.endAt).format('YYYY/MM/DD HH:mm')
                this.editDate = false
            }

            if (this.permissions != 'admin') this.serviceDetailObj.merchantId = this.stateMerchantId

            await this.getSettingByAlias();

            this.action = action
            this.dialog = true


        },
        async getSettingByAlias() {
            this.settingObj = {}
            try {
                let result1 = await api.get('/setting/facilities')

                if (result1.data) {
                    this.facilityList = JSON.parse(result1.data['contentZh'])
                }

                let result2 = await api.get('/setting/aspects')

                if (result2.data) {
                    this.aspectList = JSON.parse(result2.data['contentZh'])
                    //console.log(this.aspectList);
                }

            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
                return false
            }
        },
        async save() {
            console.log(this.serviceDetailObj);
            if (!this.timeArr.length) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_time'),
                    icon: 'mdi-close',
                })

                return false
            }

            if (this.serviceDetailObj.image == '') {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_upload_img'),
                    icon: 'mdi-close',
                })

                return false
            }

            if (this.serviceDetailObj.category == '61c2beee91779a388cef5e1b' && this.serviceDetailObj.aspects.length > 3 && this.permissions != 'admin') {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: this.$t('global_alert.warning_aspect_length'),
                    icon: 'mdi-close',
                })

                return false
            }


            this.serviceDetailObj.startAt = this.$moment(new Date(this.timeArr[0])).toISOString()
            this.serviceDetailObj.endAt = this.$moment(new Date(this.timeArr[1])).toISOString()
            if (this.permissions == 'admin') this.serviceDetailObj.merchantId = this.serviceDetailObj.merchantId._id

            let validated = this.$refs.form.validate()

            if (validated) {
                let result = false
                this.loading = true

                try {
                    if (this.action == 'create') {
                        result = await api.post('/services', this.serviceDetailObj)
                    } else {
                        result = await api.patch('/services/' + this.serviceDetailObj._id, this.serviceDetailObj)
                    }

                    if (result) {
                        let targetI18n = (this.action === 'edit') ? 'global_alert.success_edit' : 'global_alert.success_create'
                        this.resetForm()
                        this.dialog = false
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t(targetI18n),
                            icon: 'mdi-check',
                        })
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                this.loading = false
            }
        },
        showStatus(status) {
            return status ? this.$t('common.enabled') : this.$t('common.disabled')
        },
        close() {
            this.resetForm()
            this.dialog = false
        },
        removeImage(index) {
            this.serviceDetailObj.image.splice(index, 1)
        },
        copyImage(index) {
            var toCopy = this.serviceDetailObj.image[index]["small"]["path"];
            navigator.clipboard.writeText(toCopy);
        },


        resetForm() {
            this.$emit('refresh')
            this.timeArr = []
            this.uploadImageFile = []
            this.serviceDetailObj = {
                merchantId: '',
                name: '',
                category: '',
                price: '',
                originalPrice: '',
                promotionText: '',
                descriptionShort: '',
                descriptionLong: '',
                objectives: '',
                teacher: '',
                image: [],
                precautions: '',
                startAt: '',
                endAt: '',
                aspects: [],
                facilities: [],
            }
            this.editDate = true
        },
        removeFile(error, file) {
            this.$refs.pond.removeFile(file)
        },
    },
    data: () => ({
        dialog: false,
        setOptions,
        uploadImageFile: [],
        facilityList: [],
        aspectList: [],
        serviceDetailObj: {
            merchantId: '',
            name: '',
            category: '',
            price: '',
            originalPrice: '',
            promotionText: '',
            descriptionShort: '',
            descriptionLong: '',
            objectives: '',
            teacher: '',
            image: [],
            precautions: '',
            startAt: '',
            endAt: '',
            aspects: [],
            facilities: [],
        },
        timeArr: [],
        action: 'edit',
        formValid: true,
        editDate: true
    })
}
</script>